import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import Wrapper from '../styles/utilities/Wrapper';
import SocialList from './modules/SocialList';

import { colors, misc, breakpoints } from '../styles/utilities/settings';
import Menu from './modules/Menu';

const date = new Date();
const currentYear = date.getFullYear();

const Footer = () => {
  const {
    footer: { copyright, street, cityStateZip, mainEmail },
  } = useStaticQuery(graphql`
    {
      footer: sanitySiteSettings {
        copyright
        mainEmail
      }
    }
  `);
  return (
    <SFooter>
      <Wrapper>
        <Menu menuTitle="Footer Nav" />
        <div className="copy">
          <div className="contact">
            <p>
              {street && cityStateZip && (
                <>
                  {`${street}, ${cityStateZip}`}
                  <br />
                </>
              )}
              <a href={`mailto:${mainEmail}`}>{mainEmail}</a>
            </p>
          </div>
          <div className="copyright">
            <p
              dangerouslySetInnerHTML={{
                __html: copyright.replace('[date]', currentYear),
              }}
            />
            <SocialList color={colors.black} width={40} />
          </div>
        </div>
      </Wrapper>
    </SFooter>
  );
};

export default Footer;

const SFooter = styled.footer`
  background-color: ${colors.beige};
  padding: 50px 0;
  text-align: center;
  text-transform: uppercase;

  @media screen and (min-width: ${breakpoints.ipadPort}px) {
    padding: 170px 0 100px;
  }

  .menu {
    letter-spacing: 1px;
    font-weight: 600;
    margin-bottom: 30px;

    @media screen and (min-width: ${breakpoints.ipadPort}px) {
      display: flex;
      justify-content: center;
      font-size: 20px;
      margin-bottom: 80px;
    }

    li {
      margin: 0 5px;
    }
  }

  a {
    display: block;
    padding: 10px;
    color: ${colors.black};
    transition-duration: ${misc.animSpeed};

    path {
      transition-duration: ${misc.animSpeed};
    }

    &:hover {
      color: ${colors.blue};

      path {
        fill: ${colors.blue};
      }
    }
  }

  .copyright {
    padding-top: 30px;

    @media screen and (min-width: ${breakpoints.ipadPort}px) {
      text-align: left;
      padding-top: 50px;
    }

    p {
      margin-bottom: 20px;

      @media screen and (min-width: ${breakpoints.ipadPort}px) {
        margin-bottom: -25px;
      }
    }

    ul {
      display: flex;
      justify-content: center;
      align-items: center;

      li {
        + li {
          margin-left: 30px;
        }
      }
    }

    a {
      display: inline-block;
      padding: 0;
    }
  }
`;
