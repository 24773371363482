import React from 'react';
import { colors } from '../../styles/utilities/settings';

const SvgLoader = ({ name, color, width }) => (
  <>
    {name.toLowerCase() === 'circle' && <Circle color={color} width={width} />}
    {name.toLowerCase() === 'square' && <Square color={color} width={width} />}
    {name.toLowerCase() === 'play' && <Play color={color} width={width} />}
    {name.toLowerCase() === 'corn' && <Corn color={color} width={width} />}
    {name.toLowerCase() === 'email' && <Email color={color} width={width} />}
    {name.toLowerCase() === 'phone' && <Phone color={color} width={width} />}
    {name.toLowerCase() === 'marker' && <Marker color={color} width={width} />}
    {name.toLowerCase() === 'angleArrow' && (
      <AngleArrow color={color} width={width} />
    )}
    {name.toLowerCase() === 'search' && <Search color={color} width={width} />}
    {name.toLowerCase() === 'facebook' && (
      <Facebook color={color} width={width} />
    )}
    {name.toLowerCase() === 'instagram' && (
      <Instagram color={color} width={width} />
    )}
    {name.toLowerCase() === 'youtube' && (
      <Youtube color={color} width={width} />
    )}
    {name.toLowerCase() === 'pinterest' && (
      <Pinterest color={color} width={width} />
    )}
  </>
);

export default SvgLoader;

export const AngleArrow = ({ color, width }) => (
  <svg height={width || '239.073'} viewBox="0 0 330 330">
    <path
      fill={color || '#000'}
      d="M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001 c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213 C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606 C255,161.018,253.42,157.202,250.606,154.389z"
    />
  </svg>
);

export const Circle = ({ color, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={width || '239.073'}
    viewBox="0 0 239.073 239.073"
  >
    <circle
      id="Ellipse_1"
      dataName="Ellipse 1"
      cx="102.037"
      cy="102.037"
      r="102.037"
      transform="translate(17.5 17.5)"
      fill="none"
      stroke={color || '#000'}
      strokeMiterlimit="10"
      strokeWidth="35"
    />
  </svg>
);

export const Square = ({ color, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={width || '239.073'}
    viewBox="0 0 284.22 284.22"
  >
    <rect
      id="Rectangle_8"
      dataName="Rectangle 8"
      width="249.22"
      height="249.22"
      transform="translate(17.5 17.5)"
      fill="none"
      stroke={color || '#000'}
      strokeMiterlimit="10"
      strokeWidth="35"
    />
  </svg>
);

export const Play = ({ color, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={width || '172.941'}
    viewBox="0 0 172.941 172.941"
  >
    <g id="Group_2" dataName="Group 2" transform="translate(9.756 9.756)">
      <rect
        id="Rectangle_7"
        dataName="Rectangle 7"
        width="153.429"
        height="153.429"
        rx="70.459"
        transform="translate(0 0)"
        fill="none"
        stroke={color || '#000'}
        strokeMiterlimit="10"
        strokeWidth="19.512"
      />
      <path
        id="Path_25"
        dataName="Path 25"
        d="M-146.088,464.039V385.271l71.251,39.384Z"
        transform="translate(196.952 -347.94)"
        fill={color || '#000'}
      />
    </g>
  </svg>
);

export const Search = ({ color, width }) => (
  <svg
    version="1.1"
    id="Capa_1"
    x="0px"
    y="0px"
    viewBox="0 0 489.713 489.713"
    xmlSpace="preserve"
    height={width || '18'}
  >
    <g>
      <path
        fill={color || '#000'}
        d="M483.4,454.444l-121.3-121.4c28.7-35.2,46-80,46-128.9c0-112.5-91.5-204.1-204.1-204.1S0,91.644,0,204.144
        s91.5,204,204.1,204c48.8,0,93.7-17.3,128.9-46l121.3,121.3c8.3,8.3,20.9,8.3,29.2,0S491.8,462.744,483.4,454.444z M40.7,204.144
        c0-90.1,73.2-163.3,163.3-163.3s163.4,73.3,163.4,163.4s-73.3,163.4-163.4,163.4S40.7,294.244,40.7,204.144z"
      />
    </g>
  </svg>
);

export const Facebook = ({ color, width }) => (
  <svg height={width || '18'} viewBox="0 0 24 24">
    <path
      fill={color || colors.facebook}
      d="M22.675 0h-21.35c-.732 0-1.325.593-1.325 1.325v21.351c0 .731.593 1.324 1.325 1.324h11.495v-9.294h-3.128v-3.622h3.128v-2.671c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24l-1.918.001c-1.504 0-1.795.715-1.795 1.763v2.313h3.587l-.467 3.622h-3.12v9.293h6.116c.73 0 1.323-.593 1.323-1.325v-21.35c0-.732-.593-1.325-1.325-1.325z"
    />
  </svg>
);

const Instagram = ({ color, width }) => (
  <svg height={width || '18'} viewBox="0 0 24 24">
    <path
      fill={color || colors.instagram}
      d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"
    />
  </svg>
);

export const Youtube = ({ color, width }) => (
  <svg height={width || '18'} viewBox="0 0 24 24">
    <path
      fill={color || colors.youtube}
      d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"
    />
  </svg>
);

export const Pinterest = ({ color, width }) => (
  <svg height={width || '18'} viewBox="0 0 24 24">
    <path
      fill={color || colors.pinterest}
      d="M12 0c-6.627 0-12 5.372-12 12 0 5.084 3.163 9.426 7.627 11.174-.105-.949-.2-2.405.042-3.441.218-.937 1.407-5.965 1.407-5.965s-.359-.719-.359-1.782c0-1.668.967-2.914 2.171-2.914 1.023 0 1.518.769 1.518 1.69 0 1.029-.655 2.568-.994 3.995-.283 1.194.599 2.169 1.777 2.169 2.133 0 3.772-2.249 3.772-5.495 0-2.873-2.064-4.882-5.012-4.882-3.414 0-5.418 2.561-5.418 5.207 0 1.031.397 2.138.893 2.738.098.119.112.224.083.345l-.333 1.36c-.053.22-.174.267-.402.161-1.499-.698-2.436-2.889-2.436-4.649 0-3.785 2.75-7.262 7.929-7.262 4.163 0 7.398 2.967 7.398 6.931 0 4.136-2.607 7.464-6.227 7.464-1.216 0-2.359-.631-2.75-1.378l-.748 2.853c-.271 1.043-1.002 2.35-1.492 3.146 1.124.347 2.317.535 3.554.535 6.627 0 12-5.373 12-12 0-6.628-5.373-12-12-12z"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </svg>
);

export const Corn = ({ width }) => (
  <svg height={width || '83'} viewBox="0 0 297.88 270.14">
    <g id="XMLID_1_">
      <g>
        <path
          className="st0"
          style={{ fill: '#99B488' }}
          d="M134.69,254.13l8.45,3.25c1.93,0.74,3.95,1.11,5.96,1.11c2.18,0,4.33-0.43,6.38-1.29l8.14-3.35
			c-2.31,3.21-5.32,5.96-8.71,7.9l-1.35,0.77v1.56c0.02,3.5,0.02,5.06,0.05,6.06h-8.67c0-0.98,0-2.54-0.02-6.08v-1.75l-1.6-0.71
			c-4.57-1.96-7.52-4.8-9.4-7.62c-0.04-0.04-0.08-0.11-0.1-0.17l0.72,0.28C134.58,254.11,134.63,254.13,134.69,254.13z"
        />
        <path
          className="st1"
          style={{ fill: '#d8d4af' }}
          d="M289.43,67.52c1.11,4.1,1.13,8.43,0.04,12.94c-2.37-1.45-4.7-3.03-6.98-4.72c0.72-4.74,3.37-9.52,5.34-12.51
			C288.38,64.38,288.98,65.81,289.43,67.52z"
        />
        <path
          className="st1"
          style={{ fill: '#d8d4af' }}
          d="M283.26,59.94c-2.46,2.65-6.45,6.26-11.02,7.5c-2.39-2.12-4.63-4.32-6.72-6.54c2.64-1.49,5.89-1.98,9.07-1.98
			C277.81,58.92,280.93,59.43,283.26,59.94z"
        />
        <path
          className="st1"
          style={{ fill: '#d8d4af' }}
          d="M268.68,123.99c0.04,0.02,0.08,0.06,0.14,0.06c-1.49,4.61-3.58,7.97-5.44,10.27
			c-0.26,0.34-0.51,0.64-0.77,0.94c-0.15,0.17-0.28,0.34-0.42,0.49c-0.24,0.26-0.47,0.51-0.71,0.75c-0.4,0.38-0.79,0.73-1.09,1
			c-3.88-1.68-7.9-3.84-12-6.38c0.96-2.14,2.18-4.31,3.65-6.49c2.01-3.05,4.08-5.51,5.43-7C261.2,120.06,264.96,122.2,268.68,123.99
			z"
        />
        <path
          className="st1"
          style={{ fill: '#d8d4af' }}
          d="M279.09,105.42c-1.26,4.8-3.69,8.83-5.72,11.59c-0.72,0.98-1.41,1.79-1.94,2.39c-3.63-1.73-7.3-3.82-11-6.19
			c1.11-2.35,2.33-4.59,3.63-6.73c1.99-3.28,3.78-5.7,4.98-7.17C272.3,101.55,275.68,103.6,279.09,105.42z"
        />
        <path
          className="st1"
          style={{ fill: '#d8d4af' }}
          d="M288.21,85.97c-0.45,4.91-4.14,11.1-6.66,14.71c-3.29-1.75-6.51-3.72-9.67-5.85c1-2.25,2.2-4.55,3.58-6.92
			c1.78-3.07,3.55-5.59,4.72-7.22C282.81,82.57,285.48,84.34,288.21,85.97z"
        />
        <path
          className="st1"
          style={{ fill: '#d8d4af' }}
          d="M278.24,70.72c-0.56,1.69-0.96,3.44-1.15,5.21c-0.84,1.07-2.63,3.46-4.66,6.71
			c-1.58-1.24-3.41-2.71-4.84-3.89c2.47-2.52,4.44-4.87,5.38-6.02C274.82,72.33,276.6,71.6,278.24,70.72z"
        />
        <path
          className="st1"
          style={{ fill: '#d8d4af' }}
          d="M267.78,70.62c-1.37,1.62-3.55,4.05-6,6.36c-2.18,2.07-4.25,3.69-6.04,4.76c-2.99-2.88-5.68-5.76-8.07-8.65
			c0.38-0.47,0.79-0.89,1.19-1.32c0.32-0.34,0.62-0.66,0.94-0.94c0.3-0.3,0.58-0.58,0.88-0.83c0.28-0.26,0.53-0.51,0.81-0.73
			c0.26-0.23,0.51-0.45,0.75-0.62c3.52-2.99,6.7-4.44,8.43-5.1c0.04,0.04,0.08,0.11,0.13,0.15
			C262.98,66.05,265.3,68.35,267.78,70.62z"
        />
        <path
          className="st1"
          style={{ fill: '#d8d4af' }}
          d="M269.7,87.27c-1.32,2.39-2.48,4.74-3.39,7.02c-0.79,0.88-2.82,3.25-5.28,6.98c-1.68-1.19-3.33-2.49-4.95-3.82
			c-1.58-1.26-3.1-2.56-4.55-3.88c2.84-2.91,4.74-5.38,5.53-6.45c2.01-1.03,4.23-2.58,6.62-4.65
			C265.45,83.92,267.82,85.82,269.7,87.27z"
        />
        <path
          className="st1"
          style={{ fill: '#d8d4af' }}
          d="M258.24,105.84c-1.22,2.18-2.35,4.44-3.42,6.77v0.02c-0.85,0.88-3.16,3.35-5.7,6.83
			c-2.26-1.6-4.53-3.33-6.75-5.12c-2.47-2.01-4.39-3.67-6.21-5.36c2.9-3.18,4.74-5.98,5.42-7.09c2.03-1.32,4.06-2.88,6.07-4.63
			c1.6,1.45,3.31,2.9,5.06,4.33C254.5,103.07,256.36,104.5,258.24,105.84z"
        />
        <path
          className="st1"
          style={{ fill: '#d8d4af' }}
          d="M251.75,85.31c-1.24,1.53-3.25,3.9-5.9,6.4c-1.96,1.81-3.92,3.44-5.91,4.85c-3.14-3.16-6-6.43-8.52-9.72
			c0.45-0.68,0.94-1.32,1.45-1.94c0.02,0,0.02-0.02,0.05-0.04c0.21-0.28,0.47-0.56,0.72-0.84c0.3-0.32,0.58-0.61,0.88-0.91
			l0.02-0.03c3.22-3.26,6.85-5.5,8.97-6.63c0.02,0.04,0.04,0.06,0.08,0.1C246,79.46,248.76,82.4,251.75,85.31z"
        />
        <path
          className="st1"
          style={{ fill: '#d8d4af' }}
          d="M236.2,100.38c-1.11,1.6-3.03,4.14-5.7,6.79c-1.81,1.81-3.73,3.43-5.7,4.8c-3.28-3.44-6.19-6.94-8.66-10.4
			c0.72-1.11,1.47-2.13,2.24-3.07c0.19-0.28,0.4-0.54,0.66-0.79c0.41-0.47,0.81-0.92,1.22-1.35c0.19-0.21,0.38-0.42,0.57-0.59
			c2.61-2.67,5.02-4.38,6.6-5.36c0.02,0.02,0.04,0.06,0.09,0.1C230.12,93.87,233.02,97.15,236.2,100.38z"
        />
        <path
          className="st1"
          style={{ fill: '#d8d4af' }}
          d="M246.17,123.9c-1.36,2.29-2.49,4.53-3.39,6.75c-0.92,0.9-3.16,3.2-5.81,6.79c-2.73-1.88-5.53-3.95-8.32-6.24
			c-2.69-2.19-5.23-4.44-7.58-6.7c3.14-3.44,4.74-6.04,5.4-7.24l0.02-0.02c2.03-1.28,3.99-2.79,5.89-4.5
			c1.95,1.83,3.99,3.61,6.62,5.74C241.37,120.4,243.78,122.24,246.17,123.9z"
        />
        <path
          className="st1"
          style={{ fill: '#d8d4af' }}
          d="M257.25,141.94c-1.02,2.5-2.24,4.68-3.48,6.54c0.03-0.03,0.05-0.03,0.09-0.03c-0.9,1.33-1.77,2.48-2.61,3.46
			c-0.17,0.2-0.32,0.39-0.46,0.56c-1.01,1.13-1.86,1.98-2.5,2.58c-0.86-0.36-1.73-0.72-2.59-1.13c-1-0.45-2.02-0.94-3.05-1.45
			c-2.2-1.09-4.42-2.33-6.66-3.67c1.17-2.16,2.48-4.23,3.84-6.22c2.24-3.24,4.32-5.63,5.6-7.02
			C249.44,138.06,253.41,140.21,257.25,141.94z"
        />
        <path
          className="st1"
          style={{ fill: '#d8d4af' }}
          d="M222.97,165.88c0,0,0-0.03,0.02-0.03c1.09-1.77,2.26-3.48,3.52-5.14c0.15-0.21,0.3-0.41,0.45-0.62
			c2.41-3.22,4.59-5.62,5.91-6.98c1.22,0.77,2.44,1.49,3.65,2.15c0.9,0.52,1.8,0.99,2.69,1.46c1.09,0.57,2.18,1.11,3.27,1.6
			c0.9,0.42,1.79,0.83,2.69,1.19c0.06,0.05,0.11,0.07,0.15,0.07c-1.58,3.58-3.27,6.25-4.82,8.22c-0.18,0.25-0.37,0.49-0.56,0.7
			c-0.17,0.24-0.36,0.45-0.53,0.64c-1.56,1.73-2.86,2.76-3.63,3.27c-3.35-1.3-6.79-2.95-10.25-4.87c-0.9-0.47-1.77-0.98-2.67-1.52
			c0.02-0.04,0.06-0.1,0.09-0.14H222.97z"
        />
        <path
          className="st1"
          style={{ fill: '#d8d4af' }}
          d="M232.19,176.72c0.04,0.02,0.09,0.04,0.11,0.04c-1.41,2.61-2.99,4.83-4.53,6.64
			c-0.19,0.26-0.43,0.51-0.64,0.77c-0.53,0.62-1.07,1.2-1.58,1.73c-1.54,1.54-2.86,2.63-3.71,3.29c-4.19-1.67-8.5-3.82-12.9-6.45
			c1.33-1.96,2.71-3.84,4.21-5.7c0.49-0.6,0.96-1.17,1.43-1.71l0.02-0.02c2.05-2.41,3.76-4.08,4.87-5.12h0.02
			c0.36,0.23,0.72,0.47,1.11,0.68C224.48,173.18,228.37,175.12,232.19,176.72z"
        />
        <path
          className="st1"
          style={{ fill: '#d8d4af' }}
          d="M217.89,193.8c-6.41,2.71-12.24,5.83-17.27,8.9c-0.88,0.54-1.73,1.07-2.57,1.58
			c-0.38,0.26-0.76,0.49-1.13,0.75c0.15-1.97,0.19-3.87,0.17-5.62v-0.02c-0.02-1.34-0.06-2.58-0.13-3.71
			c0.26-0.3,0.54-0.62,0.79-0.9c3.53-3.95,6.22-6.53,7.73-7.9c4.29,2.61,8.54,4.81,12.66,6.53c0.02,0.03,0.05,0.05,0.07,0.05
			c-0.07,0.08-0.13,0.19-0.22,0.3C217.95,193.78,217.91,193.8,217.89,193.8z"
        />
        <path
          className="st1"
          style={{ fill: '#d8d4af' }}
          d="M206.19,175.33c0.34,0.24,0.66,0.47,1,0.69c-1.11,1.45-2.16,2.94-3.16,4.48c-0.28,0.4-0.55,0.81-0.81,1.24
			c-0.77,0.66-2.88,2.52-6.06,5.8c0.06-0.27,0.13-0.53,0.19-0.79v-0.02c0.32-1.43,0.55-2.82,0.75-4.2c0.66-4.96,0.51-9.48,0.1-13.15
			C200.87,171.53,203.54,173.52,206.19,175.33z"
        />
        <path
          className="st1"
          style={{ fill: '#d8d4af' }}
          d="M210.5,171.81c-3.05-2.03-6.17-4.33-9.29-6.85c-1.6-1.31-3.09-2.61-4.57-3.91v-0.02
			c1.35-3.95,1.73-8.13,1.63-12.08c1.64-1.41,3.2-2.89,4.67-4.42c2.74,2.69,5.62,5.27,8.63,7.68c3.11,2.54,6.3,4.89,9.43,6.98
			c-1.34,1.92-2.62,3.89-3.82,5.92c0,0.02,0,0.02-0.02,0.02c-0.94,0.79-3.35,2.9-6.47,6.53c-0.02,0-0.04,0.02-0.04,0.02
			c-0.02,0.04-0.06,0.09-0.11,0.15C210.54,171.83,210.52,171.83,210.5,171.81z"
        />
        <path
          className="st1"
          style={{ fill: '#d8d4af' }}
          d="M206.42,131.67c-0.98,1.56-2.77,4.19-5.53,7.26c-1.02,1.13-2.09,2.24-3.22,3.33
			c-0.68-4.5-1.77-8.09-2.31-9.69v-0.02c1.07-3.65,1.38-11.2,1.31-11.92C197.01,121.14,203.03,128.05,206.42,131.67z"
        />
        <path
          className="st1"
          style={{ fill: '#d8d4af' }}
          d="M233.94,141.82c-1.3,2-2.52,4.12-3.63,6.29c-0.85,0.84-3.22,3.16-6.1,6.81c-3.1-2.05-6.22-4.35-9.29-6.85
			c-2.93-2.37-5.74-4.87-8.41-7.49c2.99-3.57,4.65-6.34,5.29-7.5c2.01-1.58,3.85-3.18,5.51-4.78c2.48,2.39,5.15,4.76,7.96,7.05
			C228.18,137.69,231.06,139.87,233.94,141.82z"
        />
        <path
          className="st1"
          style={{ fill: '#d8d4af' }}
          d="M221.11,115.86c-0.94,1.45-2.71,3.86-5.7,6.85c-1.6,1.64-3.42,3.31-5.42,4.95c-3.4-3.65-6.34-7.32-8.78-10.95
			c0.69-1.41,1.56-2.75,2.54-3.97c0.2-0.24,0.39-0.47,0.58-0.69c0.19-0.23,0.38-0.46,0.6-0.68c0.32-0.36,0.64-0.7,0.98-1
			c0.3-0.3,0.58-0.58,0.88-0.83c0.25-0.24,0.51-0.5,0.76-0.69c0.22-0.21,0.45-0.4,0.67-0.55c1.49-1.33,2.9-2.35,3.99-3.06
			c0.02,0.03,0.04,0.05,0.04,0.07C214.83,108.83,217.8,112.35,221.11,115.86z"
        />
        <path
          className="st2"
          style={{ fill: '#436EAE' }}
          d="M169.04,227.12c6.55-0.74,9.84-1.23,18.62-3.3c0.06-0.05-0.26,1.08-0.2,1.04c-0.02,0.07-0.02,0.13-0.04,0.19
			c-1.34,7.13-5.93,19.52-21.47,22.83c-0.11-2.87,0.14-6.26,0.76-10.15c0.6-3.71,2.03-9.45,2.03-9.45
			C168.91,227.59,168.83,227.38,169.04,227.12z"
        />
        <path
          className="st2"
          style={{ fill: '#436EAE' }}
          d="M190.39,193.88c0.02,0,0.04-0.02,0.04-0.02c0.34-0.08,0.66-0.17,1.01-0.27c0.02,0.08,0.02,0.17,0.02,0.25
			c0.06,0.43,0.08,0.9,0.13,1.41c0.02,0.43,0.06,0.88,0.08,1.35c0.13,2.94-0.3,8.86-0.55,12.38c-0.32,3.88-2.29,8.67-2.4,8.77
			c-0.1,0.07-2.86,0.77-4.05,1.07c-0.32,0.09-0.66,0.17-0.98,0.26c-0.22,0.04-0.41,0.08-0.62,0.15h-0.02
			c-0.05,0-0.09,0.02-0.13,0.02c-0.22,0.04-0.43,0.1-0.62,0.15c-0.02,0-0.02,0-0.02,0c-0.64,0.15-1.26,0.27-1.9,0.4
			c-3.53,0.75-7.2,1.35-11,1.82c-0.15-2.16-0.21-4.44-0.15-6.86c0-0.12,0.02-0.23,0.02-0.36c0.03-0.79,0.07-1.58,0.11-2.41
			c0-0.24,0.02-0.47,0.04-0.73c0.05-0.68,0.11-1.34,0.15-2c0.02-0.13,0.02-0.24,0.05-0.37c0.32-3.39,0.76-6.42,1.17-8.66
			c0.17-0.97,0.34-1.78,0.47-2.42c1.45-0.17,2.86-0.38,4.25-0.62c3.58-0.55,7.04-1.26,10.37-2.09h0.03
			C187.42,194.74,188.92,194.33,190.39,193.88z"
        />
        <path
          className="st2"
          style={{ fill: '#436EAE' }}
          d="M166.12,196.19c-0.37,1.67-0.96,4.72-1.43,8.56v0.02c-0.05,0.22-0.07,0.43-0.09,0.64
			c-0.13,1.03-0.26,2.12-0.34,3.25c-0.19,0.02-0.36,0.04-0.56,0.06c-0.89,0.09-1.81,0.15-2.71,0.22c-0.17,0.02-0.32,0.04-0.47,0.04
			c-0.72,0.04-1.45,0.1-2.2,0.13c-0.87,0.06-9.84,0.23-10.2,0.21c-0.94,0.02-8.95-0.26-10.61-0.38c-0.15,0-0.28-0.03-0.43-0.05
			c-0.89-0.04-1.79-0.13-2.69-0.21c-0.17,0-0.32-0.02-0.49-0.04h-0.02c-0.06,0-0.11,0-0.17-0.02c-0.09-1.18-0.17-2.27-0.26-3.29
			c-0.02-0.24-0.04-0.45-0.06-0.66v-0.03c-0.39-4.09-1.24-9.45-1.24-9.45c0.13-1.05,0.24-2.1,0.32-3.14
			c0.02-0.21,0.04-0.41,0.06-0.62v-0.02c0.03-0.32,0.05-0.62,0.07-0.92c0.13-1.43,0.19-2.8,0.25-4.16v-0.02
			c0.03-0.45,0.05-0.88,0.05-1.31c1.79,0.17,3.61,0.32,5.46,0.43c1.22,0.09,2.46,0.15,3.72,0.17c0.23,0.04,0.47,0.04,0.72,0.04
			c0.09,0.03,0.17,0.03,0.26,0c0.77,0.05,1.56,0.07,2.35,0.09c0.02,0,0.02,0,0.04,0h0.28c0.79,0.02,1.58,0.04,2.37,0.02
			c0.08,0.02,0.19,0.02,0.28,0c0.27,0.02,0.55,0.02,0.83,0.02h0.57c0.79,0,1.58-0.02,2.37-0.02c0.9-0.02,1.8-0.04,2.67-0.06
			c0.09,0,0.19,0,0.28-0.02c1.52-0.05,3.03-0.11,4.53-0.22c1.47-0.08,2.92-0.19,4.39-0.34c0.45-0.04,0.9-0.09,1.35-0.13
			c0,0.15,0,0.28,0.02,0.41c0.02,0.66,0.04,1.32,0.11,1.96c0.04,0.83,0.08,1.67,0.19,2.5c0.13,1.69,0.32,3.37,0.58,5.04
			c0,0.13,0.02,0.23,0.04,0.36c0,0.02,0,0.02,0,0.04C166.27,195.51,166.18,195.83,166.12,196.19z"
        />
        <path
          className="st2"
          style={{ fill: '#436EAE' }}
          d="M164.3,224.29c-0.11,0.36-0.28,0.89-0.45,1.57c-0.4,1.48-0.96,3.59-1.49,6.11v0.02
			c-0.17,0.9-0.36,1.84-0.56,2.82c-0.7,0.06-1.41,0.11-2.11,0.15c-7.66,0.51-15.35,0.47-23.06-0.15c-0.51-3.29-1.09-5.89-1.58-7.77
			c-0.36-1.39-0.66-2.37-0.83-2.9c0-0.82,0-1.63-0.02-2.44c0-1.3-0.02-2.6-0.04-3.86c-0.05-1.3-0.09-2.59-0.15-3.84
			c2.9,0.29,5.89,0.46,8.96,0.55c1.16,0.06,2.31,0.09,3.48,0.09c0.92,0.04,1.84,0.04,2.76,0.04h0.7c1.48,0,2.86-0.02,4.23-0.07
			c0.96-0.02,1.88-0.04,2.8-0.08c0.94-0.04,1.85-0.09,2.77-0.13c1.41-0.11,2.82-0.21,4.21-0.34
			C163.79,217.71,163.92,221.13,164.3,224.29z"
        />
        <path
          className="st2"
          style={{ fill: '#436EAE' }}
          d="M160.67,249.37l-1.24,0.51l-5.97,2.39c-2.69,1.11-5.68,1.16-8.42,0.13l-6.42-2.45l-0.75-0.28
			c-0.04-3.2-0.23-6.34-0.55-9.44c3.99,0.28,7.98,0.43,11.89,0.43c3.93,0,7.88-0.15,11.76-0.43
			C160.59,243.67,160.48,246.72,160.67,249.37z"
        />
        <path
          className="st2"
          style={{ fill: '#436EAE' }}
          d="M131.66,237.67c0.47,3.44,0.75,6.96,0.83,10.57c-7.94-1.99-13.98-6.98-18-14.92
			c-1.38-2.76-2.32-5.47-2.96-7.73v-0.02c-0.17-0.69-0.32-1.33-0.47-1.93c0.81,0.22,1.62,0.41,2.45,0.62
			c5.04,1.2,10.38,2.12,15.99,2.76C130.06,228.94,130.98,232.61,131.66,237.67z"
        />
        <path
          className="st2"
          style={{ fill: '#436EAE' }}
          d="M128.82,218.95c0.02,0.87,0.04,1.75,0.04,2.62c-3.37-0.42-6.64-0.94-9.8-1.55c-0.57-0.13-1.15-0.26-1.73-0.39
			c-0.04,0-0.08,0-0.1-0.02h-0.02c-0.24-0.04-0.49-0.11-0.73-0.17h-0.02c-0.24-0.04-0.47-0.09-0.71-0.15c-0.02,0-0.02,0-0.02,0
			c-0.02,0-0.02,0-0.04,0c-0.53-0.13-1.07-0.26-1.58-0.38c-0.11-0.03-0.19-0.05-0.3-0.09h-0.02c-0.85-0.21-1.71-0.43-2.54-0.66
			l-1.6-0.45c-0.02-0.04-0.41-1.05-0.45-1.17c-0.17-0.47-0.36-1.03-0.53-1.65c-0.05-0.13-0.09-0.25-0.13-0.38
			c-0.02-0.09-0.04-0.17-0.07-0.28c-0.06-0.21-0.12-0.45-0.21-0.7c-0.08-0.41-0.21-0.86-0.3-1.33c-0.02-0.04-0.04-0.1-0.04-0.15
			c-0.13-0.51-0.24-1.07-0.34-1.66c-0.11-0.6-0.22-1.24-0.3-1.9c-0.06-0.43-0.13-0.88-0.17-1.33c-0.07-0.32-0.09-0.64-0.11-0.96
			c-0.08-0.7-0.15-1.41-0.19-2.17c-0.04-0.77-0.09-1.61-0.11-2.44c-0.02-1.02-0.02-2.11,0.02-3.24c0-0.02,0-0.02,0-0.05
			c-0.02-0.27,0-0.57,0.03-0.87c-0.03,0-0.03-0.02-0.03-0.04c0,0,0-0.03,0.03-0.05c0.04-1.19,0.12-2.43,0.23-3.71
			c0-0.02,0-0.02,0.02-0.04c0.49,0.15,0.98,0.29,1.5,0.42c1,0.3,2.02,0.58,3.07,0.84h0.02c3.55,0.91,7.26,1.7,11.12,2.32
			c1.18,0.19,2.35,0.37,3.55,0.51h0.02c0.3,0.07,0.58,0.11,0.9,0.13c0.08,0.6,0.19,1.39,0.32,2.31v0.02
			c0.3,2.18,0.64,5.17,0.89,8.94c0,0.11,0.02,0.24,0.02,0.35c0.05,0.57,0.07,1.17,0.11,1.77c0.02,0.23,0.02,0.45,0.04,0.68v0.3
			c0.07,0.81,0.09,1.64,0.13,2.48v0.3C128.76,216.26,128.8,217.6,128.82,218.95z"
        />
        <path
          className="st2"
          style={{ fill: '#436EAE' }}
          d="M191.24,122.41c0.05,1.3,0,2.6-0.12,3.91c0.02,0.02,0.02,0.02,0.02,0.02s0,0-0.02,0.02
			c-0.13,1.39-0.35,2.77-0.71,4.12c-0.94,0.3-1.88,0.58-2.84,0.83c-0.02,0.02-0.06,0.02-0.08,0.02c-1.11,0.32-2.22,0.6-3.38,0.88
			c-0.19,0.06-0.38,0.1-0.57,0.15c-0.77,0.19-1.56,0.36-2.35,0.53c-0.62,0.13-1.26,0.26-1.9,0.39c0,0,0,0.02-0.02,0.02
			c-0.02,0-0.07,0-0.11,0.02c-2.71,0.53-5.53,1-8.43,1.41c-0.75-3.63-1.3-7.2-1.63-10.59c-0.61-6.26-0.29-10.53-0.02-12.73
			c6.9-0.83,13.43-2.09,19.47-3.71c0.81,2.41,1.97,6.58,2.46,11.25c0.02,0.02,0.02,0.06,0.02,0.09
			C191.16,120.15,191.22,121.28,191.24,122.41z"
        />
        <path
          className="st2"
          style={{ fill: '#436EAE' }}
          d="M192.91,148.73c0.11,2.67-0.04,5.45-0.64,8.14c-0.09,0.44-0.22,0.89-0.32,1.32
			c-0.09,0.28-0.15,0.53-0.24,0.79c-0.17,0.06-0.34,0.11-0.53,0.17c-1.05,0.34-2.11,0.66-3.2,0.96c-3.61,1.05-7.43,1.9-11.4,2.61
			c-1.63,0.29-3.29,0.55-4.98,0.79c-0.45-2.76-0.77-5.51-0.96-8.22c-0.06-0.62-0.08-1.22-0.13-1.84c0-0.02,0-0.02,0-0.04
			c0-0.13-0.02-0.26-0.02-0.39c-0.04-1-0.08-1.98-0.08-2.92c0-2.33,0.06-5.08,0.19-6.73v-0.02c0-0.06,0-0.1,0.02-0.17
			c0.09-1.28,0.21-2.33,0.32-3.12c0.68-0.06,1.37-0.17,2.05-0.27c0.02,0,0.02,0,0.04,0c1.37-0.2,2.71-0.41,4.04-0.66
			c1.56-0.26,3.09-0.56,4.61-0.88c0.02,0,0.02-0.02,0.04-0.02c0.73-0.15,1.45-0.3,2.16-0.47c1.9-0.45,3.76-0.92,5.55-1.43
			c0.47-0.13,0.92-0.26,1.39-0.39c0.25,0.9,0.55,1.99,0.83,3.27c0.51,2.26,1,5.04,1.19,8.03v0.02
			C192.89,147.75,192.91,148.22,192.91,148.73z"
        />
        <path
          className="st2"
          style={{ fill: '#436EAE' }}
          d="M191.97,164.51c0.96,4.25,2.35,13.32-0.34,23.18c-0.02,0.09-0.04,0.17-0.07,0.26
			c-0.89,0.3-1.81,0.58-2.73,0.83h-0.02c-1.05,0.32-2.09,0.6-3.18,0.88c-4.48,1.15-9.29,2.09-14.33,2.77
			c-0.25-1.98-0.42-3.97-0.53-5.97c-0.02-0.41-0.04-0.79-0.06-1.2v-0.34c-0.03-0.56-0.05-1.11-0.05-1.67
			c-0.02-0.36-0.02-0.68-0.02-1.04v-0.22c0-0.85,0-1.66,0.04-2.45c0-0.71,0.03-1.37,0.07-2.01c0.19-3.88,0.62-6.87,0.92-8.64
			c1.94-0.26,3.86-0.56,5.72-0.88c1.17-0.21,2.3-0.43,3.46-0.68h0.02c1.07-0.22,2.13-0.43,3.18-0.69h0.02
			c0.06,0,0.09-0.02,0.15-0.04C186.89,165.98,189.47,165.3,191.97,164.51z"
        />
        <path
          className="st2"
          style={{ fill: '#436EAE' }}
          d="M131.68,166.39c0.36-2.22,0.64-4.44,0.83-6.64v-0.02c0-0.02,0-0.02,0-0.02c0.13-1.22,0.24-2.44,0.3-3.66
			c0.09,0.03,0.17,0.03,0.26,0.03c0,0,0.02,0.02,0.04,0c1.18,0.12,2.37,0.23,3.57,0.32c0,0,0.02,0.02,0.04,0
			c4.1,0.32,8.28,0.47,12.49,0.47c3.97,0,7.9-0.13,11.76-0.43c0.02,0.02,0.04,0,0.04,0c1.18-0.07,2.35-0.17,3.5-0.28
			c0.28-0.02,0.56-0.04,0.84-0.08c0.06,1,0.15,2,0.28,3.01c0,0.02,0,0.04,0.02,0.06c0.23,2.41,0.59,4.83,1.06,7.26
			c-0.23,1.13-0.79,4.03-1.11,8.2c-0.12,1.49-0.23,3.18-0.25,4.99c-0.77,0.09-1.54,0.17-2.31,0.24c-2.52,0.21-5.04,0.38-7.58,0.47
			c-0.08,0.02-0.15,0.02-0.21,0.02c-0.9,0.02-1.79,0.06-2.71,0.06c-0.81,0.02-1.62,0.04-2.46,0.04h-0.87c-0.41,0-0.83,0-1.24-0.02
			c-0.09,0.02-0.15,0.02-0.21,0c-0.84,0-1.67,0-2.48-0.02c-0.06,0-0.15,0-0.21-0.02c-0.84,0-1.69-0.02-2.52-0.08
			c-0.07,0.02-0.11,0.02-0.17,0c-0.6,0-1.18-0.05-1.78-0.07c-1.77-0.08-3.52-0.21-5.25-0.34h-0.02c-0.13-0.02-0.23-0.02-0.34-0.04
			c-0.68-0.05-1.37-0.11-2.05-0.19c0-0.26,0-0.5-0.02-0.75c0-0.24,0-0.47-0.02-0.71c-0.02-0.74-0.05-1.45-0.09-2.11
			c-0.02-0.62-0.06-1.22-0.1-1.77C132.41,170.19,131.92,167.48,131.68,166.39z"
        />
        <path
          className="st2"
          style={{ fill: '#436EAE' }}
          d="M127.62,180.95v1.24c0,0.36,0,0.74-0.02,1.13c0,0.81-0.04,1.64-0.08,2.49c0,0.54-0.02,1.05-0.07,1.58
			c-0.08,1.65-0.19,3.34-0.36,5.04c-5.32-0.72-10.31-1.7-14.99-2.96c-0.44-0.11-0.89-0.24-1.32-0.35c-0.53-0.15-1.09-0.29-1.6-0.44
			c-0.79-0.26-1.56-0.5-2.31-0.73c-0.04-0.17-0.08-0.34-0.15-0.56c-0.1-0.42-0.21-0.93-0.32-1.51c-0.85-4.16-1.79-11.96,0.15-21.35
			c2.18,0.68,4.42,1.3,6.73,1.84c0.06,0.04,0.1,0.04,0.17,0.04c1.13,0.28,2.26,0.55,3.41,0.77c0.13,0.04,0.28,0.08,0.41,0.08
			c1,0.24,2.01,0.43,3.03,0.6c2.09,0.38,4.23,0.73,6.41,1.03c0.23,1.43,0.53,3.69,0.72,6.72c0.07,0.81,0.11,1.69,0.15,2.63
			c0,0-0.02,0.02,0,0.04C127.6,179.11,127.62,180.01,127.62,180.95z"
        />
        <path
          className="st2"
          style={{ fill: '#436EAE' }}
          d="M127.58,144.14c0.09,1.69,0.15,3.7,0.11,5.94c0.02,0.06,0.02,0.13,0,0.19c0,0.87-0.02,1.79-0.07,2.73
			c-0.02,0.3-0.02,0.6-0.04,0.9c-0.02,0.38-0.04,0.79-0.06,1.17c-0.15,2.8-0.43,5.62-0.81,8.44c-1.75-0.26-3.48-0.54-5.19-0.84
			c-1.03-0.19-2.03-0.38-3.03-0.59c-2.59-0.52-5.08-1.14-7.5-1.8c-0.27-0.08-0.55-0.15-0.81-0.23h-0.02
			c-0.98-0.28-1.92-0.58-2.86-0.88c-0.23-0.06-0.47-0.15-0.68-0.21c-0.19-0.51-0.41-1.28-0.62-2.29c-0.22-0.91-0.39-2.02-0.49-3.33
			c-0.13-1.41-0.17-3.03-0.07-4.89c-0.02-0.06-0.02-0.14,0-0.21c0.07-1.3,0.2-2.73,0.43-4.27c0.23-1.66,0.58-3.46,1.05-5.4
			c0.21-0.83,0.44-1.71,0.7-2.63c0.34,0.09,0.68,0.2,1.05,0.3c0.89,0.26,1.81,0.52,2.75,0.75c0.92,0.24,1.82,0.47,2.75,0.68h0.03
			c0.47,0.11,0.96,0.22,1.43,0.32c0.02,0,0.02,0.03,0.02,0.03s0-0.03,0.02-0.03l0.02,0.03c1.56,0.34,3.16,0.66,4.78,0.96h0.02
			c1.33,0.25,2.69,0.47,4.06,0.66h0.02c0.9,0.15,1.82,0.28,2.73,0.38c0.11,0.98,0.22,2.31,0.28,3.91
			C127.6,143.99,127.6,144.08,127.58,144.14z"
        />
        <path
          className="st2"
          style={{ fill: '#436EAE' }}
          d="M165.92,137.65c0,0,0,0,0,0.02c-0.27,1.56-0.98,6.19-0.85,13.09c-10.27,1.05-21.43,1.05-32.04-0.04
			c0.1-6.77-0.43-11.51-0.6-12.98c0.75-3.38,1.28-6.9,1.64-10.46c4.83,0.44,9.91,0.68,15.14,0.68c5.14,0,10.14-0.24,14.88-0.66
			C164.49,130.65,165.11,134.13,165.92,137.65z"
        />
        <path
          className="st2"
          style={{ fill: '#436EAE' }}
          d="M164.05,109.15c-0.32,1.73-0.92,6.02-0.47,12.83c-4.59,0.43-9.4,0.64-14.37,0.64
			c-5.1,0-10.06-0.23-14.73-0.66c0.34-6.53-0.09-11.16-0.26-12.77v-0.02c1.03-3.26,1.79-6.83,2.29-10.63
			c4.2,0.34,8.45,0.51,12.7,0.51c4.65,0,8.49-0.15,12.38-0.44C162.12,102.28,162.96,105.82,164.05,109.15z"
        />
        <path
          className="st2"
          style={{ fill: '#436EAE' }}
          d="M185.2,79.6c1.37,3.57,3.82,11.83,2.61,22.76c-5.92,1.65-12.32,2.88-19.13,3.72
			c-1.01-3.33-1.71-6.9-2.09-10.61c-0.58-5.51-0.35-10.16-0.13-12.68C173.01,82.1,179.29,81.04,185.2,79.6z"
        />
        <path
          className="st2"
          style={{ fill: '#436EAE' }}
          d="M161.36,80.54v0.05c-0.24,1.7-0.73,6.47-0.32,12.72c-3.7,0.28-7.37,0.41-11.83,0.41
			c-4.06,0-8.11-0.15-12.19-0.49c0.4-6.22,0.04-11.11-0.11-12.69v-0.02c0.96-3.58,1.73-7.15,2.29-10.69
			c3.18,0.21,6.53,0.3,10.01,0.3c3.31,0,6.55-0.09,9.63-0.26C159.43,73.56,160.29,77.15,161.36,80.54z"
        />
        <path
          className="st2"
          style={{ fill: '#436EAE' }}
          d="M180.33,51.79c1.67,3.82,5.09,13.11,4.04,22.54c-5.79,1.41-11.96,2.46-18.38,3.14
			c-1.01-3.44-1.75-7.07-2.24-10.8c-0.71-5.38-0.77-9.91-0.71-12.47C169.13,53.69,174.93,52.88,180.33,51.79z"
        />
        <path
          className="st2"
          style={{ fill: '#436EAE' }}
          d="M173.37,24.1c1.78,2.88,5.77,10.65,5.85,22.46c-5.31,1.09-11.03,1.88-17.07,2.35
			c-1.07-2.78-1.93-6.47-2.5-10.83c-0.66-4.93-0.84-9.71-0.88-12.51C163.75,25.32,168.64,24.82,173.37,24.1z"
        />
        <path
          className="st2"
          style={{ fill: '#436EAE' }}
          d="M154.01,35.82c-3.01,0.02-7.07-0.02-10.2-0.09c0.72-5.65,0.87-10.09,0.89-11.89c1.8-2.65,3.1-5.66,4.08-8.67
			c1.05,3.1,2.52,6.15,4.63,8.78C153.41,25.96,153.48,30.57,154.01,35.82z"
        />
        <path
          className="st2"
          style={{ fill: '#436EAE' }}
          d="M151.68,0.29c5.94,2.72,17.21,8.95,19.84,18.68c-4.61,0.67-9.37,1.09-14.18,1.31
			C152.65,14.11,151.79,5.08,151.68,0.29z"
        />
        <path
          className="st2"
          style={{ fill: '#436EAE' }}
          d="M146.26,0c-0.36,4.69-1.54,13.72-5.59,20.26c-4.48-0.2-8.88-0.62-13.2-1.2C132.77,6.74,141.52,1.83,146.26,0z
			"
        />
        <path
          className="st2"
          style={{ fill: '#436EAE' }}
          d="M157.75,52.19v0.02c-0.09,1.71-0.24,6.3,0.38,12.34c-5.91,0.32-12.36,0.3-18.19-0.02
			c0.75-6.51,0.6-11.01,0.49-12.53v-0.02c1.03-3.35,1.9-7.02,2.57-10.93c2.69,0.06,6.21,0.11,9.24,0.11c0.87,0,1.71,0,2.48-0.03
			C155.42,45.6,156.45,49.31,157.75,52.19z"
        />
        <path
          className="st2"
          style={{ fill: '#436EAE' }}
          d="M139.32,25.55c-0.1,2.65-0.4,7.15-1.21,12.36c-0.6,3.91-1.37,7.6-2.31,10.95
			c-5.68-0.45-11.21-1.21-16.52-2.28c0.25-4.72,1.28-15.99,5.68-22.5C129.61,24.78,134.43,25.27,139.32,25.55z"
        />
        <path
          className="st2"
          style={{ fill: '#436EAE' }}
          d="M135.16,54.16c0.04,2.45-0.06,6.83-0.85,12.49c-0.49,3.54-1.22,7.17-2.12,10.78
			c-6.27-0.64-12.34-1.67-18.06-3.08c-0.23-3.84-0.3-13.43,3.91-22.58C123.53,52.88,129.29,53.69,135.16,54.16z"
        />
        <path
          className="st2"
          style={{ fill: '#436EAE' }}
          d="M131.72,82.74c0.15,2.59,0.3,7.28-0.21,12.68c-0.38,3.8-1.02,7.37-1.92,10.64
			c-6.94-0.86-13.39-2.12-19.19-3.76c-0.62-3.14-1.61-11.66,2.79-22.67C119.08,81.06,125.3,82.1,131.72,82.74z"
        />
        <path
          className="st2"
          style={{ fill: '#436EAE' }}
          d="M129.05,111.35c0.2,2.46,0.41,7-0.04,12.81c-0.28,3.59-0.75,7.13-1.41,10.55c-3.24-0.45-6.4-0.99-9.43-1.63
			c-0.05,0-0.09,0-0.13-0.02c-0.49-0.1-0.98-0.21-1.47-0.32c-0.69-0.15-1.37-0.3-2.03-0.47c-0.32-0.06-0.64-0.15-0.94-0.23
			c-0.07,0-0.11-0.02-0.15-0.05h-0.02c-1.11-0.25-2.18-0.55-3.25-0.85c-0.04-0.02-0.1-0.04-0.15-0.06
			c-0.62-0.17-1.24-0.34-1.85-0.54c-0.13-0.68-0.24-1.6-0.37-2.71c0.02,0,0.02-0.02,0.02-0.04c0,0,0,0-0.02-0.02
			c-0.1-1.18-0.17-2.59-0.19-4.19v-0.02c-0.02-1.11,0-2.28,0.11-3.56c-0.02-0.02-0.02-0.02-0.02-0.02s0,0,0.02-0.03
			c0.21-3.58,0.81-7.77,2.17-12.25C115.75,109.3,122.16,110.52,129.05,111.35z"
        />
        <path
          className="st3"
          style={{ fill: '#D16665' }}
          d="M33.82,106.7c1.28,2.11,2.5,4.37,3.61,6.7c-3.63,2.37-7.31,4.44-10.98,6.23h-0.02
			c-0.15-0.17-0.32-0.36-0.47-0.55c-0.15-0.17-0.28-0.34-0.43-0.51c-0.27-0.35-0.55-0.71-0.85-1.09c-2.09-2.69-4.7-6.81-6.08-11.77
			c0.06,0,0.1-0.04,0.17-0.08c3.39-1.79,6.74-3.82,10.05-6.09C30.04,101.02,31.83,103.41,33.82,106.7z"
        />
        <path
          className="st3"
          style={{ fill: '#D16665' }}
          d="M22.4,88.12c1.38,2.33,2.58,4.66,3.6,6.92c-3.18,2.16-6.42,4.12-9.65,5.85c-0.02,0.02-0.04,0.02-0.06,0.04
			c-2.48-3.46-6.21-9.63-6.85-14.62c0.08-0.04,0.15-0.09,0.23-0.15c2.63-1.58,5.32-3.35,8.01-5.25
			C18.87,82.53,20.62,85.07,22.4,88.12z"
        />
        <path
          className="st3"
          style={{ fill: '#D16665' }}
          d="M40.76,87.31c0.79,1.07,2.69,3.55,5.57,6.49c-1.43,1.3-2.95,2.61-4.53,3.89c-1.71,1.36-3.33,2.62-4.95,3.8
			c-2.48-3.76-4.5-6.13-5.29-6.98c-0.94-2.31-2.08-4.68-3.4-7.05c1.9-1.45,4.29-3.37,6.02-4.78C36.53,84.73,38.75,86.29,40.76,87.31
			z"
        />
        <path
          className="st3"
          style={{ fill: '#D16665' }}
          d="M49.7,72.75c0.17,0.19,0.34,0.39,0.51,0.58c-2.39,2.88-5.1,5.78-8.09,8.64c-1.79-1.08-3.86-2.73-6.04-4.78
			c-2.45-2.3-4.63-4.74-6-6.34c2.44-2.24,4.76-4.55,6.96-6.94c0.07-0.04,0.11-0.1,0.15-0.15c1.73,0.64,4.89,2.1,8.41,5.06
			c0.24,0.17,0.49,0.41,0.75,0.62c0.58,0.49,1.15,1.03,1.73,1.6c0.32,0.28,0.62,0.6,0.94,0.94C49.25,72.24,49.49,72.47,49.7,72.75z"
        />
        <path
          className="st3"
          style={{ fill: '#D16665' }}
          d="M24.89,72.97c0.94,1.15,2.91,3.48,5.38,5.99c-1.45,1.2-3.24,2.63-4.84,3.87c-2.03-3.23-3.82-5.59-4.66-6.66
			c-0.17-1.77-0.57-3.55-1.13-5.23C21.27,71.83,23.04,72.54,24.89,72.97z"
        />
        <path
          className="st3"
          style={{ fill: '#D16665' }}
          d="M10.04,63.47c1.96,3.01,4.63,7.81,5.33,12.51c-2.32,1.7-4.67,3.26-6.96,4.71c-0.06,0.05-0.1,0.07-0.17,0.11
			c-2.03-8.11-0.1-14.28,1.58-17.68C9.91,63.23,9.97,63.34,10.04,63.47z"
        />
        <path
          className="st3"
          style={{ fill: '#D16665' }}
          d="M32.47,61.01c-0.04,0.04-0.06,0.06-0.1,0.11c-2.12,2.26-4.4,4.46-6.75,6.53c-4.55-1.24-8.56-4.85-11.02-7.49
			c-0.1-0.13-0.21-0.24-0.32-0.37C18.94,58.68,26.97,57.55,32.47,61.01z"
        />
        <path
          className="st3"
          style={{ fill: '#D16665' }}
          d="M79.5,98.71c0.75,0.94,1.52,1.97,2.25,3.08c-2.52,3.5-5.43,7-8.69,10.39c-1.95-1.34-3.87-2.96-5.7-4.8
			c-2.65-2.63-4.57-5.17-5.7-6.77c3.2-3.24,6.12-6.55,8.69-9.88c0.02-0.02,0.04-0.07,0.06-0.09c1.81,1.09,4.78,3.18,7.81,6.58
			C78.65,97.69,79.08,98.2,79.5,98.71z"
        />
        <path
          className="st3"
          style={{ fill: '#D16665' }}
          d="M64.52,84.56c0.68,0.79,1.32,1.62,1.92,2.5c-2.5,3.26-5.36,6.51-8.52,9.73c-2.03-1.45-3.99-3.07-5.89-4.87
			c-2.65-2.49-4.66-4.84-5.92-6.38c2.99-2.88,5.73-5.83,8.18-8.75c0.02-0.05,0.07-0.09,0.09-0.11c2.13,1.15,5.78,3.37,8.96,6.64
			C63.75,83.73,64.13,84.13,64.52,84.56z"
        />
        <path
          className="st3"
          style={{ fill: '#D16665' }}
          d="M56.28,102.13c0.7,1.13,2.54,3.88,5.42,7.06c-1.79,1.69-3.72,3.36-6.19,5.36c-2.22,1.8-4.48,3.5-6.77,5.13
			c-2.52-3.48-4.85-5.96-5.7-6.86c-1.05-2.32-2.18-4.59-3.42-6.74c1.8-1.3,3.63-2.69,5.53-4.25c1.78-1.45,3.48-2.9,5.09-4.36
			c1.94,1.73,3.97,3.27,6.04,4.64V102.13z"
        />
        <path
          className="st3"
          style={{ fill: '#D16665' }}
          d="M45.84,124.84c1.47,2.22,2.69,4.4,3.65,6.49c-4.1,2.54-8.11,4.68-12.02,6.39c-0.26-0.24-0.56-0.49-0.88-0.82
			c-0.3-0.29-0.62-0.61-0.94-0.98c-0.51-0.55-1.06-1.24-1.64-2c-0.21-0.28-0.43-0.58-0.64-0.9c-1.54-2.18-3.12-5.06-4.31-8.73
			c0.04-0.02,0.08-0.04,0.13-0.07c3.75-1.85,7.51-3.99,11.22-6.38C41.74,119.31,43.81,121.79,45.84,124.84z"
        />
        <path
          className="st3"
          style={{ fill: '#D16665' }}
          d="M52.16,154.13c-0.86,0.41-1.71,0.79-2.57,1.13c-0.12-0.12-0.27-0.25-0.44-0.4c0-0.02-0.02-0.04-0.05-0.02
			c-0.32-0.3-0.66-0.66-1-1.05c-0.45-0.47-0.96-1.02-1.47-1.64v-0.02h-0.02c-0.11-0.13-0.22-0.26-0.32-0.41
			c-0.13-0.13-0.24-0.26-0.32-0.4c-0.67-0.79-1.31-1.67-1.97-2.65c-1.24-1.84-2.45-3.97-3.52-6.45c0.04-0.02,0.08-0.04,0.13-0.06
			c3.86-1.75,7.81-3.87,11.8-6.39c1.28,1.39,3.38,3.76,5.64,7.03c1.39,2.03,2.69,4.12,3.84,6.21c-2.24,1.34-4.46,2.58-6.66,3.67
			C54.18,153.22,53.18,153.68,52.16,154.13z"
        />
        <path
          className="st3"
          style={{ fill: '#D16665' }}
          d="M71.41,117.48c0.64,1.19,2.27,3.8,5.4,7.23c-2.3,2.23-4.84,4.49-7.57,6.73c-2.72,2.18-5.51,4.27-8.35,6.19
			c-2.67-3.56-4.89-5.87-5.81-6.77c-0.87-2.17-2.01-4.44-3.37-6.74c2.41-1.69,4.82-3.53,7.17-5.43c2.65-2.15,4.7-3.95,6.6-5.74
			C67.4,114.68,69.39,116.22,71.41,117.48z"
        />
        <path
          className="st3"
          style={{ fill: '#D16665' }}
          d="M94.13,112.97c0.98,1.24,1.86,2.56,2.54,3.97c-2.48,3.63-5.42,7.31-8.8,10.96c-1.98-1.65-3.8-3.31-5.42-4.98
			c-2.97-2.99-4.74-5.4-5.68-6.85c3.29-3.46,6.26-6.98,8.84-10.55c0.02-0.02,0.04-0.04,0.06-0.06c0.3,0.21,0.64,0.43,1.01,0.68
			c0.34,0.26,0.72,0.51,1.13,0.83c0.38,0.3,0.79,0.62,1.22,0.96l0.38,0.32l0.23,0.24c0.09,0.06,0.18,0.13,0.28,0.21
			c0.13,0.11,0.26,0.22,0.39,0.34c0.25,0.22,0.51,0.45,0.77,0.69c0.29,0.25,0.57,0.53,0.87,0.83c0.32,0.32,0.66,0.66,0.98,1.03
			c0.17,0.19,0.34,0.36,0.49,0.55C93.68,112.42,93.89,112.7,94.13,112.97z"
        />
        <path
          className="st3"
          style={{ fill: '#D16665' }}
          d="M100.38,120.89c2.5,4.79,2.74,10.69,2.74,11.6c-1.24,3.84-2.01,7.32-2.48,10.41
			c-1.3-1.23-2.52-2.49-3.67-3.77c-2.76-3.08-4.55-5.66-5.53-7.22C94.81,128.28,100.38,120.91,100.38,120.89z"
        />
        <path
          className="st3"
          style={{ fill: '#D16665' }}
          d="M101.34,187.8c0.26,1.13,0.5,1.99,0.64,2.52v0.04c-0.04,0.3-0.08,0.6-0.08,0.9l-7-8.88
			c-0.09-0.13-0.15-0.22-0.15-0.22c-0.28-0.47-0.6-0.94-0.9-1.4c0-0.03-0.02-0.03-0.04-0.05c-1.01-1.53-2.05-3.05-3.16-4.48
			c0.34-0.21,0.68-0.45,1.02-0.68c2.82-1.9,5.6-4,8.35-6.24c0.15-0.13,0.28-0.23,0.43-0.34c-0.66,5.79-0.39,10.76,0.13,14.52v0.02
			C100.81,185.22,101.09,186.65,101.34,187.8z"
        />
        <path
          className="st3"
          style={{ fill: '#D16665' }}
          d="M101.37,199.18c-0.05,1.92,0.02,3.74,0.12,5.4c-5.93-3.76-13.21-7.81-21.3-11.12
			c3.88-1.67,7.83-3.72,11.82-6.13c1.86,2.37,5.26,6.68,9.21,11.64v0.02C101.26,199.05,101.3,199.12,101.37,199.18z"
        />
        <path
          className="st3"
          style={{ fill: '#D16665' }}
          d="M84.71,177.25c1.46,1.8,2.86,3.72,4.21,5.7c-4.38,2.63-8.69,4.79-12.87,6.45c-0.41-0.3-0.92-0.7-1.52-1.19
			c-0.26-0.26-0.53-0.5-0.83-0.75c-0.43-0.43-0.9-0.86-1.37-1.35c-0.58-0.57-1.17-1.21-1.75-1.92c-0.21-0.23-0.43-0.49-0.62-0.74
			c-1.49-1.78-3.01-3.93-4.4-6.47c0.05-0.02,0.07-0.02,0.11-0.05c3.78-1.55,7.66-3.52,11.59-5.85c0.37-0.19,0.73-0.4,1.11-0.66
			c1.11,1.01,2.82,2.69,4.89,5.1c0.02,0,0.02,0.03,0.04,0.05C83.75,176.08,84.22,176.66,84.71,177.25z"
        />
        <path
          className="st3"
          style={{ fill: '#D16665' }}
          d="M100.06,149.56c-0.12,5.64,0.88,9.4,1.58,11.32c-1.58,1.45-3.24,2.86-4.99,4.29
			c-3.06,2.5-6.17,4.78-9.29,6.85c0,0,0,0.02-0.02,0.02c-0.04-0.04-0.06-0.06-0.09-0.1c-3.16-3.67-5.61-5.79-6.55-6.58
			c-0.02-0.02-0.02-0.02-0.02-0.02c-1.18-1.98-2.44-3.97-3.8-5.93c3.16-2.1,6.32-4.44,9.41-6.96c3.1-2.5,6-5.11,8.63-7.69
			c1.6,1.65,3.31,3.25,5.12,4.78C100.04,149.54,100.04,149.54,100.06,149.56z"
        />
        <path
          className="st3"
          style={{ fill: '#D16665' }}
          d="M86.02,133.23c0.61,1.09,2.3,3.91,5.33,7.54c-2.58,2.56-5.42,5.1-8.43,7.54c-3.03,2.47-6.15,4.78-9.24,6.83
			c-2.95-3.7-5.32-6.04-6.15-6.83c-1.07-2.12-2.29-4.23-3.61-6.28c2.95-2.01,5.85-4.16,8.69-6.47c2.86-2.35,5.51-4.7,7.94-7.02
			C82.22,130.09,84.05,131.7,86.02,133.23z"
        />
        <path
          className="st3"
          style={{ fill: '#D16665' }}
          d="M74.91,166.09c0.03,0.04,0.07,0.11,0.09,0.15c-0.9,0.53-1.79,1.04-2.67,1.54c-3.5,1.94-6.92,3.54-10.22,4.84
			c-0.6-0.38-1.5-1.07-2.57-2.11c-0.15-0.15-0.3-0.3-0.47-0.47c-0.06-0.07-0.1-0.13-0.17-0.19c-0.23-0.26-0.49-0.54-0.72-0.84
			c-0.22-0.25-0.45-0.53-0.66-0.83c-1.61-1.98-3.34-4.7-4.96-8.39c0.04,0,0.09-0.02,0.13-0.04c0.88-0.36,1.77-0.77,2.67-1.2
			c1.09-0.51,2.18-1.04,3.27-1.62c0.89-0.45,1.81-0.94,2.73-1.47c1.21-0.66,2.43-1.37,3.67-2.12c1.34,1.39,3.5,3.76,5.91,6.96
			c0.17,0.22,0.32,0.43,0.47,0.66c1.24,1.69,2.39,3.4,3.48,5.13H74.91z"
        />
        <path
          className="st0"
          style={{ fill: '#99B488' }}
          d="M257.15,153.04c12.64-3.48,26.34-4.39,40.73-1.17c-17.68,7.02-30.66,20.64-43.27,33.88
			c-0.17,0.17-0.32,0.34-0.49,0.51c-8.61-0.23-17.02,1.16-24.85,3.48c0.47-0.49,0.98-1.04,1.49-1.62c0.34-0.38,0.68-0.77,1.02-1.17
			c2.1-2.48,4.25-5.62,6.09-9.44h0.02c0.04-0.06,0.06-0.13,0.09-0.19c0.91-0.54,2.37-1.54,4.09-3.25c0.43-0.42,0.88-0.87,1.33-1.41
			c2.43-2.73,5.19-6.81,7.56-12.81h0.02c0-0.02,0.02-0.06,0.04-0.1c0.41-0.34,0.96-0.83,1.64-1.5c0.22-0.21,0.45-0.44,0.71-0.7
			c0.32-0.34,0.66-0.71,1.02-1.11c0.3-0.34,0.62-0.73,0.96-1.11c0.2-0.24,0.41-0.49,0.6-0.75c0.07-0.06,0.13-0.13,0.15-0.19
			C256.46,153.96,256.81,153.51,257.15,153.04z"
        />
        <path
          className="st0"
          style={{ fill: '#99B488' }}
          d="M196.13,217.24c-0.51,0.04-1.02,0.09-1.53,0.09c0.06-0.18,0.1-0.32,0.14-0.47v-0.03
			c0.11-0.25,0.18-0.51,0.24-0.74c0.08-0.22,0.15-0.41,0.19-0.62c0.26-0.86,0.47-1.71,0.64-2.58c0.02,0,0.02,0,0.02-0.03
			c0.05-0.21,0.09-0.42,0.13-0.66c0.02-0.04,0.02-0.08,0.02-0.15v-0.02c0.02,0,0.02-0.02,0.02-0.02s0,0,0.03,0c0,0,0.02,0,0.02-0.02
			c0.13-0.07,0.23-0.15,0.36-0.26c0.38-0.25,0.77-0.51,1.17-0.79c0.82-0.55,1.67-1.13,2.57-1.71c1.02-0.66,2.09-1.32,3.2-2.02
			c0.02,0,0.02,0,0.04-0.03c0.06-0.02,0.13-0.06,0.19-0.1c0.32-0.17,0.62-0.36,0.94-0.56c2.01-1.19,4.14-2.41,6.41-3.58
			c1.68-0.88,3.43-1.75,5.23-2.59l0.02-0.02c1.71-0.77,3.41-1.54,5.21-2.24c0.04-0.02,0.08-0.04,0.13-0.06
			c8.39-3.38,17.72-5.92,27.41-6.37c1.26-0.06,2.54-0.08,3.8-0.08c1.19,0.02,2.37,0.06,3.56,0.13c6.49,0.42,13.05,1.9,19.54,4.72
			c-12.58,2.47-23.23,9.41-33.63,16.18c-16.03,10.44-30.16,19.66-48.33,10.07c1.64,0,6.34-0.53,6.34-0.53
			c4.01-0.62,7.64-1.77,10.74-3.07c0,0,0,0,0.02,0c4.8-2.03,8.33-4.4,10.1-5.7c0.72-0.54,1.17-0.9,1.26-0.99
			c1.13-0.96,1.26-2.64,0.3-3.75c-0.94-1.13-2.63-1.28-3.76-0.32c-0.07,0.04-2.37,1.98-6.28,4.05c-1.43,0.75-3.07,1.54-4.91,2.22
			c0,0,0,0.02-0.02,0.02c-2.3,0.9-4.91,1.69-7.73,2.18c-0.02,0-0.02,0-0.04,0c-0.53,0.09-1.07,0.17-1.6,0.24
			c-0.02,0-0.04,0.02-0.04,0.02c-0.2,0.02-0.39,0.04-0.58,0.06h-0.02C197.16,217.18,196.64,217.22,196.13,217.24z"
        />
        <path
          className="st0"
          style={{ fill: '#99B488' }}
          d="M105.49,222.92c-18.13,9.56-32.28,0.36-48.32-10.08c-10.37-6.77-21.05-13.72-33.6-16.2
			c6.02-2.63,12.11-4.06,18.13-4.59c1.17-0.11,2.32-0.19,3.48-0.21c1.23-0.05,2.47-0.05,3.71,0c9.48,0.21,18.66,2.47,26.98,5.63
			c0.03,0,0.03,0.02,0.03,0.02c1.66,0.62,3.31,1.28,4.89,1.99c0.02,0.02,0.04,0.02,0.06,0.02h0.02c2.2,0.98,4.31,1.98,6.34,3.03
			c0.26,0.11,0.51,0.24,0.75,0.39h0.02c5.51,2.83,10.35,5.87,14.37,8.62c0.02,0.15,0.06,0.3,0.08,0.45
			c0.05,0.19,0.09,0.38,0.11,0.55c0.02,0.09,0.04,0.17,0.06,0.26c0.17,0.77,0.35,1.49,0.52,2.18V215c0.06,0.25,0.12,0.47,0.19,0.7
			c0.04,0.09,0.06,0.17,0.08,0.24c0.02,0.08,0.05,0.17,0.07,0.25c0.08,0.24,0.15,0.5,0.23,0.71c-0.89-0.07-2.84-0.3-2.84-0.3
			c-0.85-0.13-4.07-0.81-4.07-0.81c-1.69-0.39-3.29-0.88-4.77-1.41c-1.58-0.56-3.03-1.17-4.33-1.77c-4.48-2.07-7.21-4.12-7.28-4.17
			c-1.17-0.87-2.86-0.66-3.73,0.52c-0.9,1.17-0.67,2.84,0.49,3.73c0.19,0.15,1.55,1.18,3.86,2.5c0,0,0.02,0.02,0.04,0.02
			c2.42,1.41,5.87,3.16,10.08,4.57c3.01,1,6.38,1.84,10.03,2.26c1.37,0.17,2.78,0.28,4.23,0.3c0.02,0.17,0.06,0.37,0.09,0.56V222.92
			z"
        />
        <path
          className="st0"
          style={{ fill: '#99B488' }}
          d="M67.12,188.36c0.28,0.34,0.58,0.66,0.88,0.98c-7.69-2.05-15.84-3.2-24.19-2.8c-0.19-0.19-0.36-0.38-0.56-0.58
			C30.64,172.73,17.66,159.13,0,152.11c14.39-3.23,28.08-2.31,40.71,1.17c0.94,1.28,1.86,2.39,2.72,3.33
			c0.53,0.6,1.02,1.11,1.49,1.6c0.21,0.19,0.4,0.39,0.58,0.58c0.02,0,0.04,0.02,0.06,0.04c0.53,0.49,0.98,0.88,1.3,1.15
			c0,0.05,0.02,0.07,0.02,0.09h0.02c2.1,5.25,4.47,9.03,6.67,11.72c0,0.02,0,0.04,0.02,0.04c0.3,0.36,0.59,0.73,0.87,1.05
			c0.3,0.34,0.6,0.66,0.88,0.98c0.23,0.26,0.47,0.47,0.7,0.7c1.62,1.54,2.99,2.46,3.84,2.97c0.03,0.07,0.05,0.13,0.09,0.19h0.02
			c1.88,3.89,4.1,7.07,6.24,9.59C66.5,187.67,66.82,188.01,67.12,188.36z"
        />
      </g>
    </g>
  </svg>
);

export const Email = ({ color, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={width || '24'}
    viewBox="0 0 50.825 48.243"
  >
    <g
      id="Group_18"
      dataName="Group 18"
      transform="translate(-4311.024 -1785.315)"
    >
      <g
        id="Group_17"
        dataName="Group 17"
        transform="translate(4311.024 1785.315)"
      >
        <g id="Group_16" dataName="Group 16">
          <g id="Group_15" dataName="Group 15">
            <path
              id="Path_99"
              dataName="Path 99"
              d="M4347.455,1801.785v7.026c0,4.431.851,6.489,3.311,6.8,2.6.314,5.863-2.282,6.221-8.279.357-6.712-4.027-17.5-19.243-17.5-15.662,0-20.987,11.679-21.167,17.944-.358,11.546,6,20.362,20.854,21.3,14.678.9,20.092-8.68,22.241-6.846,2.015,1.745-6.936,11.322-21.66,11.322-15.618,0-27.208-10.025-26.984-24.793.179-14.633,12.441-23.45,25.822-23.45,13.066,0,25.687,7.787,24.971,21.034-.672,12.71-8.1,14.9-12.709,14.9-2.73,0-6.355-1.387-7.967-4.3a8.591,8.591,0,0,1-7.2,4.3c-6.489.224-10.427-4.608-10.472-11.321.045-6.713,3.982-11.592,10.472-11.368a8.085,8.085,0,0,1,4.833,1.7,2.458,2.458,0,0,1,.313-.135c1.344-.447,3.984-1.207,4.477-1.387C4346.159,1798.025,4347.455,1799.279,4347.455,1801.785Zm-7.383,8.144c0-3.938-1.388-6.847-4.119-6.847-2.773,0-4.161,2.909-4.161,6.847,0,3.893,1.388,6.8,4.161,6.8C4338.684,1816.731,4340.072,1813.822,4340.072,1809.929Z"
              transform="translate(-4311.024 -1785.315)"
              fill={color || colors.red}
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const Phone = ({ color, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={width || '24'}
    viewBox="0 0 43.903 47.045"
  >
    <path
      id="Path_98"
      dataName="Path 98"
      d="M3659.484,1824.115a6.361,6.361,0,0,0-1.868-2.921c-.73-.766-1.574-1.412-2.367-2.114a15.213,15.213,0,0,0-3.954-2.753,4.284,4.284,0,0,0-4.572.322,40.172,40.172,0,0,0-3.337,2.965,3.52,3.52,0,0,1-3.666.871,12.123,12.123,0,0,1-4.728-3.091,38.707,38.707,0,0,1-4.993-5.717,13.271,13.271,0,0,1-2.731-5.584,3.369,3.369,0,0,1,1.13-3.2c.885-.808,1.854-1.538,2.8-2.275a4.671,4.671,0,0,0,1.573-6,21.4,21.4,0,0,0-1.546-2.985,19.674,19.674,0,0,0-3.462-4.691c-1.342-1.195-2.781-1.483-4.222-.387-1.4,1.075-2.689,2.311-3.988,3.505a10.779,10.779,0,0,0-2.27,3.23,16.047,16.047,0,0,0-1.614,6.855c.154,1.328.224,2.661.505,3.962a26.706,26.706,0,0,0,3.6,8.653,62.971,62.971,0,0,0,3.779,5.359c1.328,1.7,2.767,3.314,4.235,4.895a38.551,38.551,0,0,0,3.068,2.887c1.075.92,2.205,1.805,3.358,2.647a24.166,24.166,0,0,0,5.7,3.105,18.7,18.7,0,0,0,5.858,1.257,19.674,19.674,0,0,0,2.71-.182,12.027,12.027,0,0,0,6.055-2.3,44.47,44.47,0,0,0,4.228-3.674A2.671,2.671,0,0,0,3659.484,1824.115Z"
      transform="translate(-3615.672 -1785.869)"
      fill={color || colors.beige}
    />
  </svg>
);

export const Marker = ({ color, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={width || '24'}
    viewBox="0 0 72.478 102.665"
  >
    <path
      id="Path_137"
      data-name="Path 137"
      d="M1319.606,774.941a36.257,36.257,0,0,0-36.239,36.271c0,14.265,18.464,45.618,29.025,62.409a8.524,8.524,0,0,0,14.429,0c10.561-16.791,29.023-48.144,29.023-62.409A36.236,36.236,0,0,0,1319.606,774.941Zm0,55.948a19.415,19.415,0,1,1,19.414-19.414A19.413,19.413,0,0,1,1319.606,830.889Z"
      transform="translate(-1283.367 -774.941)"
      fill={color || colors.red}
    />
  </svg>
);
