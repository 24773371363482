import { createGlobalStyle } from 'styled-components';
import { spacing, breakpoints } from './utilities/settings';
const HeaderStyles = createGlobalStyle`

  body {
    ${({ open }) => (open ? 'overflow: hidden;' : '')};
  }

  main {
    ${({ transparentHeader }) =>
      transparentHeader
        ? ''
        : `
      padding-top: ${spacing.headerHeight};

      @media screen and (max-width: ${breakpoints.ipadPort - 1}px) {
        padding-top: ${spacing.headerMobile};
      }
    `}
  }
`;

export default HeaderStyles;
