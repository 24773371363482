import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
// import IcomoonReact from 'icomoon-react/build/src/IcomoonReact';
import styled from 'styled-components';
import SvgLoader from '../helpers/SvgLoader';
// import iconSet from '../../fonts/selection.json';
// import { colors } from '../../styles/utilities/settings';

const SocialList = ({ color, width }) => {
  const {
    settings: { socialList },
  } = useStaticQuery(graphql`
    {
      settings: sanitySiteSettings {
        socialList {
          _key
          icon
          link
        }
      }
    }
  `);
  return (
    socialList && (
      <SSocialList>
        {socialList.map(({ icon, link, _key }) => (
          <li key={_key}>
            <a
              name={icon}
              title={icon}
              href={link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <SvgLoader
                name={icon}
                width={width || 24}
                color={color || null}
              />
            </a>
          </li>
        ))}
      </SSocialList>
    )
  );
};

export default SocialList;

const SSocialList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;

  li {
    + li {
      margin-left: 10px;
    }
  }
`;
